<template>
  <nav>
   
  </nav>
  <router-view/>
</template>

<style>

</style>
